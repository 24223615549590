import Form from "../components/form";
import Navbar from "../components/Navbar";

function Dp(){
    return(
        <> 
        <Navbar />
        <Form />
        </>
    )
}

export default Dp;